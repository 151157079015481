<script lang="ts" setup>
const { t, locale } = useI18n();
const config = useRuntimeConfig();

const switchLocale = computed(() =>
  locale.value === 'ru'
    ? {
        link: getSiteUrl(config.public.projectEnvironment, 'host', 'en'),
        text: 'English',
      }
    : {
        link: getSiteUrl(config.public.projectEnvironment, 'host', 'ru'),
        text: 'Русский',
      },
);
</script>

<template>
  <footer class="footer pb-20 pt-4 px-2 md:px-4 xl:px-6 relative bg-indigo-950">
    <div class="container mx-auto flex flex-col md:flex-row">
      <div class="pb-4 pt-6 md:py-6 md:-ml-4">
        <p class="px-4 mb-4 text-sm text-slate-300">
          {{ t('site_map') }}
        </p>
        <nav class="flex flex-nowrap font-medium flex-col lg:flex-row gap-1">
          <div class="flex flex-col items-start gap-1 mr-6 xl:mr-8">
            <NuxtLink to="/">
              <Button text plain>
                {{ t('navigation.home') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/about-fants">
              <Button text plain>
                {{ t('navigation.about_sex_tasks') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/game/settings">
              <Button text plain>
                {{ t('navigation.play_sex_tasks') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/articles">
              <Button text plain>
                {{ t('navigation.articles') }}
              </Button>
            </NuxtLink>
          </div>
          <div class="flex flex-col items-start gap-1 mr-6 xl:mr-8">
            <NuxtLink to="/about-fants#interests">
              <Button text plain>
                {{ t('navigation.about_interests_game') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/interests">
              <Button text plain>
                {{ t('navigation.play_in_the_interests') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/interests/results/compare">
              <Button text plain>
                {{ t('navigation.show_matches') }}
              </Button>
            </NuxtLink>
          </div>
          <div class="flex flex-col items-start gap-1 mr-6 xl:mr-8">
            <NuxtLink to="/interests/packs">
              <Button text plain>
                {{ t('navigation.select_questions') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/interests/change">
              <Button text plain>
                {{ t('navigation.edit_answers') }}
              </Button>
            </NuxtLink>
            <NuxtLink to="/interests/results/archive">
              <Button text plain>
                {{ t('navigation.match_archive') }}
              </Button>
            </NuxtLink>
          </div>
        </nav>
      </div>
      <div class="md:ml-auto md:-mr-4">
        <div class="pb-4 md:pt-6 md:pb-0">
          <p class="px-4 text-sm text-slate-300 md:text-right mb-4">
            {{ t('languages') }}
          </p>
          <nav class="flex flex-nowap font-medium flex-col md:flex-row">
            <a :href="switchLocale.link" class="md:ml-auto">
              <Button text plain>
                {{ switchLocale.text }}
              </Button>
            </a>
          </nav>
        </div>
        <div class="pb-6 md:pt-5 md:ml-auto">
          <p class="px-4 text-sm text-slate-300 md:text-right mb-4">
            {{ t('contacts') }}
          </p>
          <nav class="flex flex-nowap font-medium flex-col md:flex-row">
            <a
              href="mailto:info@sex-play.ru"
              target="_blank"
              class="md:ml-auto"
            >
              <Button text plain>info@sex-play.ru</Button>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<i18n src="~/locales/navigation.yaml"></i18n>
<i18n>
en:
  site_map: Site's map
  languages: Languages
  contacts: Contacts

ru:
  site_map: Карта сайта
  languages: Языки
  contacts: Контакты
</i18n>
